import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

import { Disqus, CommentCount } from "gatsby-plugin-disqus"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl

  const { previous, next } = pageContext

  const disqusConfig = {
    url: `${siteUrl + location.pathname}`,
    identifier: post.id,
    title: post.title,
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        url={disqusConfig.url}
        twitterImage={
          post.frontmatter.twitterImage || post.frontmatter.featuredImage
        }
        linkedInImage={
          post.frontmatter.linkedInImage || post.frontmatter.featuredImage
        }
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date} ({post.fields.readingTime.text})
          </p>
          <Image
            maxWidth={1200}
            fluid={post.frontmatter.banner.childImageSharp.fluid}
            alt={post.frontmatter.bannerAlt}
            style={{
              minWidth: "100vw",
              marginLeft: "calc(-50vw + 50%)",
              marginBottom: "30px",
              maxHeight: "40rem",
            }}
          />
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <a
        href="https://ko-fi.com/C0C61YEOV"
        target="_blank"
        style={{ backgroundImage: "none" }}
      >
        <img
          height="36"
          style={{ border: "0px", height: "36px" }}
          src="https://cdn.ko-fi.com/cdn/kofi2.png?v=2"
          border="0"
          alt="Buy Me a Coffee at ko-fi.com"
        />
      </a>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <CommentCount config={disqusConfig} placeholder={"..."} />
      <Disqus config={disqusConfig} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        banner {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage: banner {
          childImageSharp {
            resize(width: 1200, quality: 100) {
              src
              height
              width
            }
          }
        }
        bannerAlt
        twitterImage {
          childImageSharp {
            resize(width: 1200, quality: 100) {
              src
              height
              width
            }
          }
        }
        linkedInImage {
          childImageSharp {
            resize(width: 1200, quality: 100) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`
